import { $, $$, addEventListenerAll, offset } from './helper/utils';
import outline from './helper/outline';
// import inView from './helper/inView';
import navigation from './components/navigation';

const sliderMap = new Map();
const sliderDragging = new Map();

outline();
navigation();

addEventListenerAll($$('.scrolldown'), 'click', (e) => {
  e.preventDefault();

  window.scrollTo({
    top: $('.banner').offsetHeight,
    behavior: 'smooth',
  });
});

addEventListenerAll($$('.scroll-section'), 'click', function () {
  console.log(this.closest('.content-section').nextElementSibling);
  window.scrollTo({
    top: offset(this.closest('.content-section').nextElementSibling)?.top,
    behavior: 'smooth',
  });
});

export default function () {
  if ($('.siema') !== null) {
    import('./components/slider')
      .then((slider) => slider.default(sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  if ($('.custom-select') !== null) {
    import('./components/forms/select')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  }

  if ($('.home') !== null) {
    import('./components/home')
      .catch((err) => {
        console.warn('Chunk home.js loading failed', err);
      });
  }

  if ($('.products') !== null) {
    import('./components/products')
      .catch((err) => {
        console.warn('Chunk products.js loading failed', err);
      });
  }

  // inView($$('.lightbox'), () => {
  //   import('./components/lightbox.js')
  //     .then((lightbox) => lightbox.default('.lightbox', sliderMap, sliderDragging))
  //     .catch((err) => {
  //       console.warn('Chunk lightbox.js loading failed', err);
  //     });
  // }, true);

  if ($$('.nav-tabs, [data-toggle="collapse"]').length) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      gsap.fromTo(href, { duration: 0.3, opacity: 0 }, { opacity: 1, ease: 'linear' });

      const tabbq = e.relatedTarget.parentElement.querySelector('.tabbg');
      gsap.to(tabbq, {
        duration: 0.325,
        x: e.relatedTarget.offsetLeft,
        scaleX: e.relatedTarget.offsetWidth / tabbq.offsetWidth,
        ease: 'sine.inOut',
      });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }

        for (const tab of $$('.nav-tabs')) {
          const tabbg = document.createElement('div');
          tabbg.className = 'tabbg';
          tab.prepend(tabbg);
          gsap.set(tabbg, {
            scaleX: tab.querySelector('.active').offsetWidth / tabbg.offsetWidth,
          });
        }

        for (const el of $$('.nav-tabs .tab-link')) {
          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }
}
